<app-roam-card [title]="title()">
  <div actRef class="align-center gap-10">
    @if (acl.isManager()) {
      <button-manage icon="add" label="Add New" 
        (onClick)="actionClick.emit('add-contact-record')"
      />
    }
  <button-manage icon="add" label="SMS Blast"/>
    <button-action
      label="contactsWithoutBorder"
        type="vendor-contact-record" />
    </div>
  <ng-container cardContent>
    <div class="mat-table-dark dark">
      <app-table
        [localPagination]="true"
        [tableConfig]="tableConfig"
        [emptyRow]="true"
        [isLoading]="isLoading()"
        (pageChange)="updateTable($event, tableConfig)">
        <ng-template #customTemplate let-key="key" let-data="data">
          @if (key === "date") {
            <div class="justify-start gap-10">
              <div
                class="d-flex icon_front"
                [inlineSVG]="'./assets/svg/ic-phone.svg'"></div>
              <div>
                <div class="tc-black mb-2 fw-500">
                  {{ (data.date | date: "MM/dd/yyyy") || '-' }}
                </div>
                <label-status [status]="data.statusName" />
              </div>
            </div>
          }
          @if (key === "action") {
            <div
              class="d-flex icon_btn svg-18"
              [inlineSVG]="'./assets/svg/ic-comment.svg'"></div>
          }
        </ng-template>
      </app-table>
    </div>
  </ng-container>
</app-roam-card>
